import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter} from '@angular/router';
import {provideServiceWorker} from '@angular/service-worker';
import {provideHttpClient, withInterceptors, withInterceptorsFromDi} from '@angular/common/http';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {firstValueFrom} from 'rxjs';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import localeNo from '@angular/common/locales/nb';
import {registerLocaleData} from '@angular/common';
registerLocaleData(localeNo);
import {KeycloakService} from 'keycloak-angular';
import {KeycloakBearerInterceptorProvider, KeycloakInitializerProvider} from './keycloak.config';
import {httpRequestInterceptor} from './http-request.interceptor';
import {translateConfig} from './translate.config';
import {environment} from '../environments/environment';
import {routes} from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'}
    },
    {
      // provides environment values for injected projects (libraries)
      provide: 'environment',
      useValue: environment
    },
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([httpRequestInterceptor])),
    importProvidersFrom(TranslateModule.forRoot(translateConfig)),
    provideHttpClient(withInterceptorsFromDi()), // withInterceptorsFromDi required for KeycloakBearerInterceptorProvider
    provideServiceWorker('ngsw-worker-ibricks.js', {
      // if SW need to be used locally be sure that ngsw-worker.js file is present in /public folder
      enabled: true, // environment.production
      registrationStrategy: 'registerImmediately'
    })

    // TODO IndexedDb integration
    // importProvidersFrom(NgxIndexedDBModule.forRoot(dbConfig)),

    // TODO try this provider to limit changeDetection triggers
    // provideZoneChangeDetection({ eventCoalescing: true }),
  ]
};

if (environment.authMethod === 'JWT') {
  appConfig.providers.push(KeycloakService, KeycloakBearerInterceptorProvider, KeycloakInitializerProvider);
}

/**
 * Forces an application initialization to wait until translation files will be loaded.
 * Translations GET is async so initially could be missing.
 * @param translateService
 */
function appInitializerFactory(translateService: TranslateService): () => Promise<unknown> {
  return () => firstValueFrom(translateService.get('_'));
}

/**
 * TODO * * * * * * * * * * * * Keycloak authorization integration (instructions) * * * * * * * * * * * * * * * * * * * * * * *
 + Uncomment keycloak section in {@link AuthService.isAuthenticated}.
 + Uncomment keycloak implementation of {@link AuthService.checkAuthorization} and remove dummy replacement with same name.
 + Uncomment keycloak implementation of {@link AuthService.logOut}.
 + Uncomment keycloak implementation of {@link AuthService.handleProfileError}.
 + Remove Auth route after keycloak integration from {@link routes}.
 */

/**
 * TODO * * * * * * * * * * * * IndexedDb/webSocket integration (instructions) * * * * * * * * * * * * * * * * * * * * * * * *
 * Uncomment importProvidersFrom(NgxIndexedDBModule.forRoot(dbConfig)) in {@link appConfig.providers}.
 * Uncomment getOfflineDataAndSubscribeAutoSync and appService.connectWebSocket in {@link AppComponent.subscribeRouter} handler.
 * Uncomment sync tasks menu item in SidenavUserMenuComponent template.
 * Replace fake db-service with db-service from modules/shared/services/db-service-backup folder.
 * Remove setTimeout temporary fix in {@link EntityListComponent.openCategoryDialog} handler.
 * Uncomment {@link ProjectService.getProjectsPicker}.
 * Uncomment {@link QualityService.getBooksPicker}.
 * Uncomment {@link QualityService.getLifespansPicker}.
 * Uncomment {@link QualityService.getQaDocumentsPicker}.
 * Uncomment {@link QualityService.getSubDocuments}.
 * Uncomment {@link SchemaService.getFormRegistrations}.
 * Uncomment {@link ContactService.getContactsByCompanyUnid}.
 * Uncomment {@link ContactService.getCompaniesPicker}.
 * Uncomment {@link IncidentService.getIncidentsTodo}.
 * Uncomment {@link IncidentService.getWorkflowsPicker}.
 */
