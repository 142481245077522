import {HttpInterceptorFn, HttpParams} from '@angular/common/http';
import {inject} from '@angular/core';
import {AuthService} from './modules/shared/services/auth.service';
import {environment} from '../environments/environment';
import {ParamEnum} from './modules/shared/enums/param.enum';

export const httpRequestInterceptor: HttpInterceptorFn = (request, next) => {
  const apiUrls: string[] = [
    environment.ibricksApiV2,
    environment.ibricksApiV3,
    environment.upload,
    environment.imageUpload
  ];

  const getParams = (): HttpParams => {
    const orgParam = request.params.get(ParamEnum.Org) || '';
    if (!orgParam) {
      const org = inject(AuthService).org;
      if (org) {
        return request.params.set(ParamEnum.Org, org);
      }
    }
    return request.params;
  };

  if (apiUrls.some(i => request.url.includes(i))) {
    const anonymousUse = request.params.get(ParamEnum.AnonymousUse) === '1';

    request = request.clone({
      withCredentials: anonymousUse ? false : environment.production, // TODO check on anonymous registrations
      params: getParams()
    });

    if (!environment.production && environment.authMethod === 'BASIC' && environment.username && environment.password) {
      request = request.clone({
        headers: request.headers.append(
          'Authorization',
          'Basic ' + btoa(`${environment.username}:${environment.password}`)
        )
      });
    }
  }

  if (request.url.includes(environment.iStudyApi)) {
    request = request.clone({
      params: getParams()
    });
  }

  return next(request);
};
