<ng-container *ngIf="isSpinnerVisible">
  <div class="loading-spinner"></div>
  <div class="loading-info">Please wait while iBricks is loading...</div>
</ng-container>

<ng-template #container></ng-template>

<app-simple-toolbar *ngIf="!isSidenavVisible && isUiLess"></app-simple-toolbar>

<router-outlet *ngIf="!isSidenavVisible"></router-outlet>

<div *ngIf="isProfileAvailable !== null && !isProfileAvailable && !isUiLess" class="profile-error">
  <img src="icons/icon-144x144.png" alt="iBricks logo" />
  <p>
    Sorry, there is something wrong with your user profile. Please, try to
    <a href="" (click)="refreshPage()">refresh page</a>
    or send a message to
    <a href="mailto:support@ibricks.no">support&#64;ibricks.no</a>
    if you are having problems accessing this page.
  </p>
  <p>
    Beklager, det er en feil med din brukerprofil. Vennligst prøv å
    <a href="" (click)="refreshPage()">last inn siden på nytt</a>
    eller send en e-post til
    <a href="mailto:support@ibricks.no">support&#64;ibricks.no</a>
    hvis du har problemer med tilgangen til denne siden.
  </p>
</div>
