import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '../../../common';
import {MenuLogoComponent} from '../sidenav/menu-logo/menu-logo.component';
import {LayoutService} from '../../services/layout.service';
import {INavItem} from '../../interfaces';

@Component({
  selector: 'app-simple-toolbar',
  standalone: true,
  templateUrl: './simple-toolbar.component.html',
  styleUrl: './simple-toolbar.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatToolbarModule, MatIconModule, MenuLogoComponent]
})
export class SimpleToolbarComponent {
  constructor(private layoutService: LayoutService) {}

  get currentRoute(): INavItem | null {
    return this.layoutService.currentRoute();
  }
}
