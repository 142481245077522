import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterOutlet} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppService} from './app.service';
import {LayoutService} from './modules/shared/services/layout.service';
import {OfflineService} from './modules/shared/services/offline.service';
import {SimpleToolbarComponent} from './modules/shared/layout/simple-toolbar/simple-toolbar.component';
import {isUiLessTemplate} from './modules/shared/utilities';
import {RouteEnum} from './modules/shared/enums/route.enum';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.sass',
  imports: [CommonModule, RouterOutlet, SimpleToolbarComponent]
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
  private readonly destroyer = new Subject<void>();
  public isUiLess?: boolean;
  public isSidenavVisible?: boolean;
  public isProfileAvailable: boolean | null = null; // null === not handled yet
  public isSpinnerVisible = true;

  constructor(
    private layoutService: LayoutService,
    private changeDetector: ChangeDetectorRef,
    private appService: AppService,
    private offlineService: OfflineService
  ) {}

  ngOnInit(): void {
    this.subscribeRouter();
    this.appService.subscribeSwUpdate();
    this.appService.subscribeLogsEvent();
    if (environment.authMethod === 'JWT') {
      this.checkProfileAvailability();
    }
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  /**
   * Checks profile availability and sets boolean value into {@link isProfileAvailable} after 1 second.
   * Timeout was added to hide unnecessary error message in uiLessTemplates.
   * Checks if user is not on /auth route to exclude injection of unnecessary info into template (Ltpa case).
   * {@link isUiLess} depended on {@link subscribeRouter} and has own handler.
   * @private
   */
  private checkProfileAvailability(): void {
    this.appService.isProfileAvailable.pipe(takeUntil(this.destroyer)).subscribe(isProfileAvailable => {
      setTimeout(() => {
        if (!location.pathname.includes(RouteEnum.Auth)) {
          this.isProfileAvailable = isProfileAvailable;
          this.isSpinnerVisible = false;
        }
      }, 1000);
    });
  }

  private subscribeRouter(): void {
    this.layoutService
      .getNavigationEnd()
      .pipe(takeUntil(this.destroyer))
      .subscribe(e => {
        this.appService.setTheme(e.url); // set theme each time when route changes
        const isAuthPage = e.url.includes(RouteEnum.Auth);

        if (this.appService.isAuthenticated && !this.container?.length && !isAuthPage) {
          this.initSidenav();
          // TODO IndexedDb integration
          // this.getOfflineDataAndSubscribeAutoSync();
          // this.appService.connectWebSocket();
        } else {
          this.isSpinnerVisible = false;
          this.isUiLess = isUiLessTemplate(e.url);
        }
      });
  }

  private getOfflineDataAndSubscribeAutoSync(): void {
    this.offlineService
      .getOfflineData()
      .subscribe(() => this.offlineService.autoSync().pipe(takeUntil(this.destroyer)).subscribe());
  }

  /**
   * Sets true into {@link isSidenavVisible} variable to remove router outlet from template.
   * Router outlet should be removed from DOM before component creation to prevent double router outlet in template.
   * @private
   */
  private initSidenav(): void {
    this.isSpinnerVisible = false;
    this.isSidenavVisible = true;
    this.changeDetector.detectChanges();
    this.appService.initSidenav(this.container);
  }

  public refreshPage(): void {
    sessionStorage.clear();
    location.reload();
  }
}
