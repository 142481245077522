import {KeycloakBearerInterceptor, KeycloakService} from 'keycloak-angular';
import {APP_INITIALIZER, Provider} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {environment} from '../environments/environment';
import {isUiLessTemplate} from './modules/shared/utilities';

export const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

export const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  deps: [KeycloakService],
  multi: true
};

function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
  return () =>
    keycloak.init({
      config: environment.keycloakConfig,
      loadUserProfileAtStartUp: true,
      initOptions: {
        onLoad: isUiLessTemplate(location.pathname) ? undefined : 'login-required',
        checkLoginIframe: environment.production
      },
      bearerExcludedUrls: [environment.msGraphApi, environment.azureApi, environment.translateApi]
    });
}
